.e-Navbar__wrapper {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 100;
}

.b-Navbar {
  height: 100%;
}

.e-Navbar__container {
  height: 100%;
  align-items: center;

  ul {
    height: 50%;
    width: 100%;
    justify-content: space-around;
  }

  ul li {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 10px;
    color: #fff;

    &:hover {
      border-bottom: 2px solid red;
    }
  }
}

//MEDIA QUERY-------------------------------------------------------------
@media (min-width: 468px) {
  .e-Navbar__container {
    ul li {
      font-size: 12px;
    }
  }
}

@media (min-width: 800px) {
  .e-Navbar__container {
    ul li {
      font-size: 14px;
    }
  }
}
