.b-ProjectCard {
  width: 100%;
  height: 40vh;
}

.e-ProjectCard__img {
  border: 1px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 5px;
  min-width: 50%;

  &:hover {
    transform: translate(-36%);
    transition: all 0.8s ease-out;
  }
}

.e-ProjectCard__right {
  border: 1px solid gray;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px;
  background: rgba(48, 47, 47, 0.9);
  min-width: 70%;
  height: 75%;
  margin: 48px 0 0 -150px;
  z-index: 2;
  gap: 2rem;

  & h2 {
    font-size: 2rem;
    color: red;
    display: flex;
    justify-content: center;
    margin: 1rem 0 -0.5rem 0;
  }

  & p {
    font-size: 1.4rem;
  }

  & .e-ProjectCard__button-group {
    margin-top: -0.2rem;
    justify-content: space-around;

    & .e-ProjectCard__button {
      border: none;
      border-radius: 20px;
      font-size: 1rem;
      padding: 10px 20px;

      &:hover {
        background: #455166;
        color: #fff;
        cursor: pointer;
        transform: scaleX(1.2);
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

//MEDIA QUERY-----------------------------------------------------------
@media (min-width: 468px) {
  .b-ProjectCard {
    width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 800px) {
  .b-ProjectCard {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .b-ProjectCard {
    width: 60%;
  }
}
