.e-About__wrapper {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      to right,
      rgba(10, 10, 10, 0.9),
      rgba(10, 10, 10, 0.9)
    ),
    url(../../images/pic1.jpg) no-repeat;
  color: #fff;
  overflow: hidden;
}

.b-About {
  height: 100%;
  position: relative;
  top: 100px;
}

.e-About__container {
  height: 100%;
  width: 100%;
}

.e-About__card {
  width: 90%;
  height: 84%;
  margin: 0 auto;
  gap: 3rem;
  overflow-y: scroll;
  scrollbar-width: none;

  & h1 {
    font-size: 3rem;
    text-align: center;

    & i {
      font-size: 2.5rem;
      color: rgb(165, 162, 162);
    }
  }

  & p {
    font-size: 1.4rem;
    text-align: center;
  }

  & h2 {
    font-size: 2.2rem;
    text-align: center;
  }

  & div {
    flex-direction: column;
    gap: 1rem;
  }

  & .e-About__ul {
    border: 1px solid gray;
    padding: 20px 110px 20px 40px;
    border-radius: 5px;
    box-shadow: 1px 2px 5px 0px;
    font-size: 1.2rem;
    gap: 1rem;

    & h3 {
      font-size: 1.6rem;
      color: red;
    }
  }
}

//MEDIA QUERY-------------------------------------------
@media (min-width: 800px) {
  .e-About__card {
    width: 80%;

    & div {
      flex-direction: row;
      justify-content: space-around;
    }
  }
}
