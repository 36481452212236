.e-Home__wrapper {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      to right,
      rgba(10, 10, 10, 0.9),
      rgba(10, 10, 10, 0.9),
      rgba(50, 50, 50, 0.8),
      rgba(10, 10, 10, 0.85),
      rgba(10, 10, 10, 0.95)
    ),
    url(../../images/pic1.jpg) no-repeat;
  color: #fff;
  overflow: hidden;
}

.b-Home {
  height: 89%;
  position: relative;
  top: 100px;
}

.e-Home__container {
  height: 70%;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
}

.e-Home__left {
  color: rgb(206, 197, 197);
  order: 2;
  line-height: 1.6;

  & h3 {
    text-align: left;
  }

  & span {
    font-size: 30px;
  }

  .e-Home__buttons {
    flex-direction: column;
    align-items: center;
  }

  .e-Home__button {
    border: none;
    padding: 10px 20px;
    margin-top: 1rem;
    background: rgb(225, 225, 225);
    font-size: 1rem;
    border-radius: 20px;

    &:hover {
      background: #455166;
      color: #fff;
      cursor: pointer;
      transform: scaleX(1.2);
      transition: all 0.4s ease-in-out;
    }
  }
}

.e-Home__right {
  order: 1;

  .e-Home__name {
    color: rgb(136, 129, 129);
    justify-content: center;
  }
}

//MEDIA QUERY------------------------------------------
@media (min-width: 800px) {
  .e-Home__container {
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .e-Home__left {
    order: 1;

    .e-Home__buttons {
      flex-direction: row;
      gap: 2rem;
      align-items: center;
    }
  }

  .e-Home__right {
    order: 2;
  }
}
